.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(237, 242, 248, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4x);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 3;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        height: 30px;

        @media screen and (min-width: 1024px) {
            height: 35px;
        }

        @media screen and (min-width: 2000px) {
            height: 45px;
        }

    }
}


.link {
    padding: 0 0.5rem;
    position: relative;
    display: inline-block;
    width: fit-content;
    transition: after-width 0.3s ease;
}

.link::after {
    top: 100%;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    background-color: var(--secondary-color);
    transition: all 0.3s ease;
    transform-origin: center;
}

.link:hover::after {
    transform: scaleX(1);
}

.app__navbar-links {
    //? flex-grow:1; flex-shrink: 1, flex-basis: 0
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div {
            width: 5px;
            height: 5px;
            background-color: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
        }

        a {
            color: var(--gray-color);
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            font-weight: 900;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: var(--secondary-color);
            }
        }

        &:hover {
            div {
                background-color: var(--secondary-color);
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);

    svg {
        cursor: pointer;
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        background: url("../../assets/codelines.png");
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);


        svg {
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;

                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: var(--secondary-color);
                    }
                }

            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }

}

.app__navbar-menu .menu-item {
    display: flex;
    align-items: center;

    svg {
        width: 25px;
    }
}