#home {
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;


    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (max-width: 1023px) {
        position: relative;
    }


    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding-top: 6rem 1rem 2rem;
    }

}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    .role .p-text {
        letter-spacing: 1.5px;
        font-weight: 600;
        color: var(--secondary-color);
    }

    @media screen and (max-width: 1023px) {
        position: absolute;
        top: 10%;
    }

    @media screen and (min-width: 1024px) {
        justify-content: center;
    }

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}


.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    h1 {
        color: var(--gray-color);
    }

    .badge-cmp,
    .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        z-index: 2;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 0.5rem;

        @media screen and (min-width: 475px) {
            margin-top: 1rem;
        }

        @media screen and (min-width: 768px) {
            margin-top: 2rem;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 3rem;
        }

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: justify;
        }

    }

    span {
        font-size: 3rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .tag-desc {
        display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        .tag-desc {
            display: inherit;
        }
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div .circle-cmp {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(n) .circle-cmp {
        transition: all 0.25s ease-in-out;
    }

    @media screen and (max-width: 1200px) {
        height: unset;
        bottom: 0;
        position: absolute;
        align-items: flex-end;
        z-index: 2;
    }

    @media screen and (max-width: 375px) {
        div:nth-child(1) .circle-cmp {
            width: 60px;
            height: 60px;

            img {
                width: 70%;
                height: 70%;
            }
        }

        div:nth-child(2) .circle-cmp {
            margin: 1.5rem 0rem;
            width: 120px;
            height: 120px;

            img {
                width: 130%;
                height: auto;
            }
        }

        div:nth-child(3) .circle-cmp {
            width: 80px;
            height: 80px;

            img {
                width: 105%;
                height: 105%;
            }
        }
    }

    @media screen and (min-width: 376px) {

        div:nth-child(1) .circle-cmp {
            margin: 3rem 0;
            width: 70px;
            height: 70px;

            img {
                width: 70%;
                height: 70%;
            }
        }

        div:nth-child(2) .circle-cmp {
            margin: 4rem 0;
            width: 120px;
            height: 120px;

            img {
                width: 150%;
                height: auto;
            }
        }

        div:nth-child(3) .circle-cmp {
            margin: 3rem 0;
            width: 80px;
            height: 80px;

            img {
                width: 105%;
                height: 105%;
            }
        }
    }

    @media screen and (min-width: 575px) {

        div:nth-child(1) .circle-cmp {
            margin: 3rem 0;
            width: 100px;
            height: 100px;
        }

        div:nth-child(2) .circle-cmp {
            margin: 3rem 0;
            width: 180px;
            height: 180px;
        }

        div:nth-child(3) .circle-cmp {
            margin: 3rem 0;
            width: 110px;
            height: 110px;
        }
    }

    @media screen and (min-width: 1024px) {
        justify-content: center;

        div:nth-child(1) .circle-cmp {
            // margin: unset;
            width: 100px;
            height: 100px;
        }

        div:nth-child(2) .circle-cmp {
            margin: 3rem 5rem;
            width: 220px;
            height: 220px;
        }

        div:nth-child(3) .circle-cmp {
            // margin: unset;
            width: 120px;
            height: 120px;
        }
    }

    @media screen and (min-width: 1200px) {
        div:nth-child(2) .circle-cmp {
            margin: 1.5rem;
            width: 220px;
            height: 220px;
        }

    }


    @media screen and (min-width: 2000px) {
        div:nth-child(1) .circle-cmp {
            width: 220px;
            height: 220px;
        }

        div:nth-child(2) .circle-cmp {
            margin: 1.5rem;
            width: 340px;
            height: 340px;
        }

        div:nth-child(3) .circle-cmp {
            width: 200px;
            height: 200px;
        }

    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div .circle-cmp {
            margin: 1rem;
        }
    }
}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    @media screen and (min-width: 1023px) {
        justify-content: center;
        align-items: center;
    }

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
        height: 100%;

        @media screen and (min-width: 768px) {
            max-height: 60vh;
        }

        @media screen and (max-width: 1023px) {
            height: 70%;
        }

        @media screen and (min-width: 1024px) {
            height: 110%;
        }
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 0;
        width: 100%;
        height: 90%;

        @media screen and (max-width:475px) {
            bottom: -10%;
        }
    }



    margin: 0;


    @media screen and (min-width: 385px) and (max-width: 1200px) {

        margin: 2rem 0;

    }

    .description {
        display: none;
        position: absolute;
        bottom: 0;
        z-index: 2;
        justify-content: end;
        margin-bottom: 25%;

        .tag-cmp {
            background-color: var(--white-color);
        }

        @media screen and (min-width: 2000px) {
            margin-bottom: 5%;
        }
    }

    @media screen and (min-width: 1201px) {
        .description {
            display: flex;
        }
    }
}