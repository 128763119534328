.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: var(--white-color);
        color: var(--black-color);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: var(--white-color);
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: var(--white-color);
    }
}

.app__work-portfolio {
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;

    max-height: 600px !important;

    @media screen and (max-width: 475px) {
        height: 490px !important;
    }

    @media screen and (min-width: 2000px) {
        max-height: 800px !important;
    }

    .app__work-item {

        justify-content: flex-start !important;
        display: flex;
        align-self: self-start;
        width: 270px;
        flex-direction: column;

        margin: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: var(--white-color);
        color: var(--black-color);

        cursor: pointer;
        transition: all 0.3s ease;

        h4 {
            text-align: center;
        }

        p {
            text-align: center;
        }

        img {
            max-height: 250px;
            object-fit: cover;
            object-position: center;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            width: 100%;
            padding: 1rem;
        }
    }
}

.app__work-img {
    width: 100%;
    height: 230px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div {
        width: 50px;
        height: 50px;
        border: 0.5px solid var(--brown-color);
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: var(--white-color);

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;

        cursor: pointer;
        transition: all 0.3s ease;

        svg {
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }

        &:hover {
            background-color: var(--brown-color);
        }
    }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;


    h4 {
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__work-tag {
        position: absolute;

        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: var(--white-color);
        top: -25px;
    }
}

.swiper-container {
    width: 90%;
    height: 100%;
    position: relative;

    .swiper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: baseline;
    }


}

.app__work-more-button {
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--secondary-color);
    color: var(--white-color);

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
        background-color: var(--secondary-color);
        color: var(--white-color);
    }

    @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
    }
}